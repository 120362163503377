<template>
  <div class="modal-manager">
    <Teleport
      v-for="(modal, i) in modals"
      to="body"
      :key="modal.id"
      :disabled="!usePortal"
    >
      <!--
        Portal needs an additional static div here, or else it sometimes has
        trouble removing the modal when it closes
      -->
      <div>
        <component
          :is="modal.type"
          :id="modal.id"
          :options="modal"
          :style="{
            zIndex: 801 + i, // allow multiple dialogs to stack
          }"
          @closeModal="onCloseModal(modal.id)"
          @assetsUploaded="onAssetsUploaded($event, modal)"
        />
      </div>
    </Teleport>
    <!-- Unfortunately using only isActive in the config results in an infinite loop, so the
      entire component is hidden when it's not needed -->
    <Teleport
      v-if="myContentModalOptions && myContentModalOptions.config.isActive"
      to="body"
      :disabled="!usePortal"
    >
      <MyContentModal
        class="modal--studio modal--studio-fixed"
        :asset="myContentModalOptions.asset"
        :config="{
          modalType: 'choose',
          baseURL: domains.deDomain,
          isActive: true,
          nebulaSvg,
          ...myContentModalOptions.config,
        }"
        :style="{
          zIndex: 801 + modals.length, // allow multiple dialogs to stack
        }"
        @closeModal="closeMyContentModal"
        @chooseAssets="onChooseAssets"
      />
    </Teleport>
  </div>
</template>

<script>
import { MyContentModal, MyContentUploadModal } from '@discoveryedu/platform-components';
import nebulaSvg from '@discoveryedu/nebula-icons/nebula.svg';
import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import * as modals from '@/components/modals';
import {
  useModalStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  name: 'ModalManager',
  components: {
    MyContentModal,
    MyContentUploadModal,
    ...modals, // expose all our modals to the template
  },
  props: {
    usePortal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nebulaSvg,
    };
  },
  computed: {
    ...mapState(useModalStore, [
      'domains',
      'modals',
      'myContentModalOptions',
    ]),
    myContentModalIsActive() {
      return get(this.myContentModalOptions, 'config.isActive');
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
      types.SET_MY_CONTENT_MODAL_OPTIONS,
    ]),
    closeMyContentModal() {
      this[types.SET_MY_CONTENT_MODAL_OPTIONS]({
        config: {
          isActive: false,
        },
      });
    },
    onAssetsUploaded(assets, modal) {
      // For the My Content Upload modal, pass uploaded assets back to the
      // original component that wanted them (e.g. Add menu or Replace button)
      if (modal.onAssetsUploaded) {
        modal.onAssetsUploaded(assets);
      }
    },
    onChooseAssets(...args) {
      if (this.myContentModalOptions.onChooseAssets) {
        this.myContentModalOptions.onChooseAssets(...args);
      }
      this.closeMyContentModal();
    },
    onCloseModal(modalId) {
      this[types.CLOSE_MODAL]({ id: modalId });
    },
  },
  watch: {
    myContentModalIsActive(isActive) {
      // When the My Content Modal closes itself but doesn't let us know, remove it.
      if (!isActive && this.myContentModalOptions && this.myContentModalOptions.config.isActive) {
        this.closeMyContentModal();
      }
    },
  },
};
</script>
