import { defineStore } from 'pinia';
import {
  get, first, last,
} from 'lodash-es';
import {
  ASSET_TYPE_TEI,
  PROCESSING_ASSET_STATUSES,
} from '@/lib/constants';
import { httpsUrl } from '@/lib/utils/url';
import {
  useAssessmentStore,
  useEditorStore,
  useUploadStore,
} from '@/stores';
import * as types from '@/lib/constants/store';
import loadAsyncPlayerChunk from '@/lib/utils/load-async-player-chunk';

const useAssetStore = defineStore('studio-asset', {
  state: () => ({
    assets: {},
    assetIds: [],
    assetPlayerLink: null,
  }),
  getters: {
    /**
     * Returns true if we've cached all of the assets on the artifact
     * @returns Boolean
     */
    assetsLoaded() {
      const cachedAssets = Object.keys(this.assets);

      // if all of the stored assets are cached, then we're good
      return this.assetIds.length && this.assetIds.every((id) => cachedAssets.includes(id));
    },
  },
  actions: {
    /**
     * Request all assets and uploads for a draft or creation, so they're
     * already available when the user reaches whichever slide they're on
     * @param {object} context - Pinia context
     */
    [types.CACHE_ASSETS_AND_UPLOADS]() {
      const editorStore = useEditorStore();
      const uploadStore = useUploadStore();
      if (!editorStore.draft || !editorStore.draft.pages) return;

      const cachedAssetIds = Object.keys(this.assets);
      const cachedUploadIds = Object.keys(uploadStore.uploads);

      // Using a loop here even though it looks a little dirtier,
      // since two maps would take longer
      const assetIds = [];
      const uploadIds = [];

      // Find all assets and uploads in the current draft.
      const pagesAndModals = [...editorStore.draft.pages, ...editorStore.draft.modals];
      pagesAndModals.forEach(({ modules, notes, options }) => {
        [...modules, ...(notes || [])].forEach((mod) => {
          if (['asset', 'upload'].includes(mod.type)
            && mod.options.asset_id
            && !cachedAssetIds.includes(mod.options.asset_id)
          ) {
            // Prep uncached assets
            assetIds.push(mod.options.asset_id);
            cachedAssetIds.push(mod.options.asset_id);
          } else if (mod.type === 'upload'
            && mod.options.upload_id
            && !cachedUploadIds.includes(mod.options.upload_id)
          ) {
            // Prep uncached uploads
            uploadIds.push(mod.options.upload_id);
            cachedUploadIds.push(mod.options.upload_id);
          }
        });

        // Prep full-slide assets
        // eslint-disable-next-line
        if (options?.asset_id
          && !cachedAssetIds.includes(options.asset_id)) {
          assetIds.push(options.asset_id);
          cachedAssetIds.push(options.asset_id);
        }

        // Prep slide background images
        if (get(options, 'theme.background_image')
          && !cachedAssetIds.includes(options.theme.background_image)) {
          assetIds.push(options.theme.background_image);
          cachedAssetIds.push(options.theme.background_image);
        }
      });

      if (assetIds.length) {
        // Batching disabled for now
        // Send asset requests in batches of 30
        // const assetIdChunks = chunk(assetIds, 1);

        // assetIdChunks.forEach((ids) => {
        //   const assetQuery = ids.map(id => `asset_id=${id}`).join('&');
        //   deApi.get(`/assets/?${assetQuery}`)
        //     .then((res) => {
        //       if (res.status !== 200) throw res;
        //       res.data.assets.forEach((asset) => {
        //         commit(types.CACHE_ASSET, asset);
        //       });

        //       // The bulk asset api is good for returning lots of assets at once, but it
        //       // doesn't include error messages for the ones it couldn't find. We need to
        //       // get those manually
        //       const receivedIds = res.data.assets.map(asset => asset.id);
        //       without(ids, ...receivedIds).forEach((missingId) => {
        //         dispatch(types.CACHE_ASSET, missingId);
        //       });
        //     }).catch((result) => {
        //       commit(types.SET_ERROR, {
        //         active: true,
        //         error: result.response.data,
        //       });
        //     });
        // });
        this.assetIds = assetIds;
        assetIds.forEach((assetId) => {
          this[types.CACHE_ASSET](assetId);
        });
      }

      if (uploadIds.length) {
        uploadStore[types.CACHE_UPLOADS](uploadIds);
      }
    },
    async [types.GET_ASSET]({ assetId, fromCache }) {
      const editorStore = useEditorStore();
      const getAssetFromContent = async () => {
        const getAssetResult = {};

        try {
          const assetPromises = [
            this.contentApi.get(`/api/v1/assets/${assetId}`),
            this.contentApi.get(`/api/v1/media_files/assets/${assetId}`),
          ];
          const assetResponses = await Promise.all(assetPromises);

          const error = assetResponses.find((response) => response.status !== 200);
          if (error) throw error;

          const asset = get(first(assetResponses), 'data.asset');
          const mediaFiles = get(last(assetResponses), 'data.media_files', [])
            .map((mediaFile) => ({
              ...mediaFile,
              url: httpsUrl(mediaFile.open_url),
              format: { id: mediaFile.format_id },
            }));

          asset.type_id = asset.type?.id;
          asset.group = asset.type?.group?.grouping?.name;
          asset.media_files = mediaFiles;
          asset.status = {
            id: asset.status_id,
          };

          /*
            We need to set the type.name field to the friendly_name to make assets returned
            from the content api consistent with assets returned from the DE api.
          */
          if (asset.type.friendly_name) asset.type.name = asset.type.friendly_name;

          getAssetResult.asset = asset;
        } catch (error) {
          getAssetResult.error = error;
        }

        return getAssetResult;
      };

      let getAssetResult = {};

      if (editorStore.loadAssetsFromContentApi) {
        getAssetResult = await getAssetFromContent();
      } else {
        let url = `/assets/${assetId}`;
        if (!fromCache) {
          url += '?from_cache=0';
        }

        try {
          const result = await this.deApi.get(url);
          if (result.status !== 200) {
            throw result;
          }
          getAssetResult.asset = result.data?.asset;
        } catch (error) {
          getAssetResult.error = error;
        }
      }

      return getAssetResult;
    },
    [types.CACHE_ASSET](assetId) {
      if (!assetId) return;

      const retryWait = 5000;
      const maxRetriesOnError = 5;
      let retriesOnError = 0;
      const maxPollCount = 20;
      let pollCount = 0;

      // Assets need to be polled since UCC assets may still be processing the first
      // time they are requested. This poll will only run once for DE-created assets
      // or for UCC assets that are finished processing
      const poll = async (fromCache = true) => {
        try {
          const { asset, error } = await this[types.GET_ASSET]({ assetId, fromCache });
          if (error) throw error;
          pollCount += 1;

          if (PROCESSING_ASSET_STATUSES.includes(asset.status.id)) {
            // If the asset is still processing, wait and try again
            this.assets[assetId] = asset;

            if (pollCount < maxPollCount) {
              setTimeout(() => {
                poll(false);
              }, retryWait);
            } else {
              // if we've exceeded the poll wait time, post an error
              const errorAsset = {
                error: `Exceeded the max number of request retries of ${maxPollCount}`,
                id: assetId,
              };
              this.assets[errorAsset.id] = errorAsset;
            }
          } else {
            await loadAsyncPlayerChunk(asset);
            this.assets[assetId] = asset;

            // If the asset is a TEI, cache its TEI data as well
            if (asset.type_id === ASSET_TYPE_TEI) {
              const assessmentStore = useAssessmentStore();
              assessmentStore[types.CACHE_TEI]({ teiId: asset.id });
            }
          }
        } catch (error) {
          if (retriesOnError < maxRetriesOnError) {
            setTimeout(() => {
              poll(false);
            }, retryWait);
            retriesOnError += 1;
            // Show the asset as processing while we retry.
            const statusAsset = {
              status: { id: first(PROCESSING_ASSET_STATUSES) },
              id: assetId,
            };
            this.assets[statusAsset.id] = statusAsset;
          } else {
            // Display either the error message from Python or the Axios error itself
            const errorAsset = {
              error: get(error, 'response.data.meta', error),
              id: assetId,
            };
            this.assets[errorAsset.id] = errorAsset;
          }
        }
      };

      poll();
    },
    [types.SET_ASSET_PLAYER_LINK](assetPlayerLink) {
      this.assetPlayerLink = assetPlayerLink;
    },
    [types.GET_ASSET_PLAYER_LINK]() {
      return this.assetPlayerLink;
    },
  },
});

export default useAssetStore;
