/**
 * The audio player, video player, and full-slide TEIs are not used in all
 * drafts and can be pretty heavy; this slows down the initial pageload. But if
 * we only load them when they're rendered (the default behavior), it will
 * cause jumpy animations between slides when we first land on a slide that
 * needs that code. These load what we need for each asset type as soon as
 * we get asset data back from the api; that way we don't load unnecessary code
 * but also don't get jumpy animations
 */

const loadedAsyncPlayerChunks = {
  audio: false,
  video: false,
  SelectedResponseTEI: false,
  ConnectionsTEI: false,
  SelectObjectTEI: false,
  ConstructedResponseTEI: false,
  TilingTEI: false,
};

const teiChunkLookup = {
  'selected-response': 'SelectedResponseTEI',
  connections: 'ConnectionsTEI',
  tiling: 'TilingTEI',
  'select-object': 'SelectObjectTEI',
  'constructed-response': 'ConstructedResponseTEI',
};

async function loadTEIChunk(assetSubtype) {
  const teiType = teiChunkLookup[assetSubtype];
  if (!teiType) return;

  if (!loadedAsyncPlayerChunks[teiType]) {
    await import(`@/components/teis/${teiType}.vue`);
    loadedAsyncPlayerChunks[teiType] = true;
  }
}

// After assets load we can load async player chunks
export default async function loadAsyncPlayerChunk(asset) {
  switch (asset.group) {
    case 'video':
      if (!loadedAsyncPlayerChunks.video) {
        await import('@/components/media/VideoViewer.vue');
        loadedAsyncPlayerChunks.video = true;
      }
      break;
    case 'audio':
      if (!loadedAsyncPlayerChunks.audio) {
        await import('@/components/media/AudioViewer.vue');
        loadedAsyncPlayerChunks.audio = true;
      }
      break;
    default:
      await loadTEIChunk(asset?.subtype?.name || asset?.sub_type?.name);
  }
}
