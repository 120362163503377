import { defineAsyncComponent } from 'vue';

export const ActivityTemplateModal = defineAsyncComponent(() => import('@/components/modals/ActivityTemplateModal.vue'));
export const AddSlideModal = defineAsyncComponent(() => import('@/components/modals/AddSlideModal.vue'));
export const AssetShareModal = defineAsyncComponent(() => import('@/components/modals/AssetShareModal.vue'));
export const AuthRedirectModal = defineAsyncComponent(() => import('@/components/modals/AuthRedirectModal.vue'));
export const ConfirmAssignModal = defineAsyncComponent(() => import('@/components/modals/ConfirmAssignModal.vue'));
export const ConfirmBackModal = defineAsyncComponent(() => import('@/components/modals/ConfirmBackModal.vue'));
export const ConfirmDeleteBoard = defineAsyncComponent(() => import('@/components/modals/ConfirmDeleteBoard.vue'));
export const ConfirmNavigateAwayModal = defineAsyncComponent(() => import('@/components/modals/ConfirmNavigateAwayModal.vue'));
export const ConfirmRestoreCreation = defineAsyncComponent(() => import('@/components/modals/ConfirmRestoreCreation.vue'));
export const ConfirmStillEditingModal = defineAsyncComponent(() => import('@/components/modals/ConfirmStillEditingModal.vue'));
export const CopyCreationModal = defineAsyncComponent(() => import('@/components/modals/CopyCreationModal.vue'));
export const EditorialUploadInfo = defineAsyncComponent(() => import('@/components/modals/EditorialUploadInfo.vue'));
export const EditorialUploadModal = defineAsyncComponent(() => import('@/components/modals/EditorialUploadModal.vue'));
export const FromQuicklist = defineAsyncComponent(() => import('@/components/modals/FromQuicklist.vue'));
export const ImageZoomModal = defineAsyncComponent(() => import('@/components/modals/ImageZoomModal.vue'));
export const InactiveAssetsModal = defineAsyncComponent(() => import('@/components/modals/InactiveAssetsModal.vue'));
export const PrintLayoutModal = defineAsyncComponent(() => import('@/components/modals/PrintLayoutModal.vue'));
export const PublishModal = defineAsyncComponent(() => import('@/components/modals/PublishModal.vue'));
export const RemoveAllQuicklistItemsModal = defineAsyncComponent(() => import('@/components/modals/RemoveAllQuicklistItemsModal.vue'));
export const SearchAssets = defineAsyncComponent(() => import('@/components/modals/SearchAssets.vue'));
export const SharingModal = defineAsyncComponent(() => import('@/components/modals/SharingModal.vue'));
export const SubmitAssignmentModal = defineAsyncComponent(() => import('@/components/modals/SubmitAssignmentModal.vue'));
export const TEICheckingAnswersModal = defineAsyncComponent(() => import('@/components/modals/TEICheckingAnswersModal.vue'));
export const TEIConnectionCardModal = defineAsyncComponent(() => import('@/components/modals/TEIConnectionCardModal.vue'));
export const TEIErrorModal = defineAsyncComponent(() => import('@/components/modals/TEIErrorModal.vue'));
export const TEIImageZoomModal = defineAsyncComponent(() => import('@/components/modals/TEIImageZoomModal.vue'));
export const TEIInstructionsCardModal = defineAsyncComponent(() => import('@/components/modals/TEIInstructionsCardModal.vue'));
export const TEISelectedResponseZoomModal = defineAsyncComponent(() => import('@/components/modals/TEISelectedResponseZoomModal.vue'));
export const TEIStartOverModal = defineAsyncComponent(() => import('@/components/modals/TEIStartOverModal.vue'));
export const UnsavedAnswersModal = defineAsyncComponent(() => import('@/components/modals/UnsavedAnswersModal.vue'));
